<template>
    <div>
      <v-container>
        <base-material-card
          icon="mdi-clipboard-text"
          title="จัดการคำนำหน้าชื่อ"
          class="px-5 py-3"
        >
          <v-card class="mb-4 pa-2">
            <v-row>
              <v-col cols="12" lg="6" class="text-right">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                  single-line
                  hide-details
                  dense
                  filled
                  class="mb-2"
                />
              </v-col>
              <v-col cols="12" lg="6" class="text-right">
                <v-btn
                  large
                  right
                  depressed
                  color="primary"
                  @click.native="prefixAdd()"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-data-table
            color="success"
            :loading="loading"
            :headers="headers"
            :items="prefixs"
            :search="search"
          >
           
            <template v-slot:item.actions="{ item }">
              <v-icon color="yellow" @click.stop="prefixEdit(item.prefix_id)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.action_s="{ item }">
              <v-icon color="red" @click.stop="prefixDelete(item.prefix_id)">
                mdi-delete
              </v-icon>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
              >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
            >
          </v-data-table>
        </base-material-card>
  
        <!--addprefixdialog  -->
        <v-layout row justify-center>
          <v-dialog v-model="addprefixdialog" persistent max-width="50%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                icon="mdi-account-multiple"
                title="เพิ่มคำนำหน้าชื่อ"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text>
                <v-form ref="addprefixform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>  
                      <v-flex md12>
                        <v-text-field                
                        label="คำนำหน้าชื่อ"       
                          v-model="addprefix.prefix_name"
                          required
                          :rules="[v => !!v || '']"
                          rounded
                          outlined
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>                 
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  large
                  @click.stop="addprefixdialog = false"
                  rounded
                  outlined
                  ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="success"
                  @click.stop="addprefixSubmit()"
                  rounded
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model deleteprefixdialog -->
        <v-layout>
          <v-dialog v-model="deleteprefixdialog" persistent max-width="40%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-delete"
                title="ลบคำนำหน้าชื่อ"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form ref="deleteprefixform" lazy-validation>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                          ยืนยันการลบรายการ <span class="font-weight-bold">{{ editprefix.prefix_name }} </span> 
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large @click.stop="deleteprefixdialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="red darken-3"
                  @click.stop="deleteprefixSubmit()"
                  dark
                >
                  <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model editprefixdialog -->
        <v-layout row justify-center>
          <v-dialog v-model="editprefixdialog" persistent max-width="50%">
            <v-card class="mx-auto pa-6">
              <base-material-card
                color="warning"
                icon="mdi-clipboard-text"
                title="แก้ไขคำนำหน้าชื่อ"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-form ref="editprefixform" lazy-validation>
                  <v-container grid-list-md>           
                    <v-layout wrap>  
                      <v-flex md12>
                        <v-text-field                
                        label="คำนำหน้าชื่อ"       
                          v-model="editprefix.prefix_name"
                          required
                          :rules="[v => !!v || '']"
                          rounded
                          outlined
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>
                 
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined @click.stop="editprefixdialog = false" rounded>
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn                  
                  color="warning"
                  @click.stop="editprefixSubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึกแก้ไข
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          multi-line
          vertical
          top
          auto-height
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <br />
          {{ snackbar.text }}
          <v-btn dark @click="snackbar.show = false">
            Close
          </v-btn>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  import VueMoment from "vue-moment";
  import moment from "moment-timezone";
  export default {
    name: "prefix",
    data() {
      return {
        VueMoment,
        moment,
        loading: true,
        ApiKey: "HRvec2021",
        valid: true,
        addprefixdialog: false,
        editprefixdialog: false,
        deleteprefixdialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },
      
        prefixs: [],
        addprefix: {},
        editprefix: {},
        search: "",
        pagination: {},
        headers: [
          { text: "รหัส", align: "center", value: "prefix_id" },
          { text: "คำนำหน้าชื่อ", align: "center", value: "prefix_name" },      
          {
            text: "แก้ไข",
            align: "center",
            value: "actions",
            icon: "mdi-file-document-edit"
          },
          {
            text: "ลบ",
            align: "center",
            value: "action_s",
            icon: "mdi-delete-forever"
          }
        ],
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],      
        data_syslog: {}
      };
    },
    async mounted() {
      this.prefixQueryAll();
    },
    methods: {
      async prefixQueryAll() {
        this.loading = true;
        let result = await this.$http
          .post("prefix.php", {
            ApiKey: this.ApiKey
          })
          .finally(() => (this.loading = false));
        this.prefixs = result.data;
      },

      async prefixAdd() {
        this.addprefix = {};   
        this.addprefixdialog = true;
      },

      async addprefixSubmit() {    
        this.addprefix.ApiKey = this.ApiKey;
        let result = await this.$http.post("prefix.insert.php", this.addprefix);       
  
        if (result.data.status == true) {
          this.prefix = result.data;
          Swal.fire({
              icon: 'success',
              title: 'ดำเนินการบันทึกข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500,
            })
          this.prefixQueryAll();         
        } else {
          Swal.fire({
              icon: 'warning',
              title: 'ดำเนินการผิดพลาด',
              showConfirmButton: false,
              timer: 1500,
            })   
        }
        this.addprefixdialog = false;
      },
  
      async prefixEdit(prefix_id) {
        let result = await this.$http.post("prefix.php", {
          ApiKey: this.ApiKey,
          prefix_id: prefix_id
        });
        this.editprefix = result.data;  
        this.editprefixdialog = true;
      },
      async editprefixSubmit() {         
        this.editprefix.ApiKey = this.ApiKey;
        let result = await this.$http.post("prefix.update.php", this.editprefix);      
        if (result.data.status == true) {
          this.prefix = result.data;
          Swal.fire({
              icon: 'success',
              title: 'แก้ไขข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500,
            })
          this.prefixQueryAll();        
        } else {
          Swal.fire({
              icon: 'warning',
              title: 'แก้ไขข้อมูลผิดพลาด',
              showConfirmButton: false,
              timer: 1500,
            })
        }
        this.editprefixdialog = false;
      },
      async prefixDelete(prefix_id) {
        let result = await this.$http.post("prefix.php", {
          ApiKey: this.ApiKey,
          prefix_id: prefix_id
        });
        this.editprefix = result.data;
        this.deleteprefixdialog = true;        
      },
      async deleteprefixSubmit() {
        if (this.$refs.deleteprefixform.validate()) {
          this.editprefix.ApiKey = this.ApiKey;
          let result = await this.$http.post(
            "prefix.delete.php",
            this.editprefix
          );
  
          if (result.data.status == true) {
            this.prefix = result.data;
            Swal.fire({
              icon: 'success',
              title: 'ดำเนินการลบข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500,
            })
            this.prefixQueryAll();             
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'ดำเนินการลบผิดพลาด',
              showConfirmButton: false,
              timer: 1500,
            })
          }
          this.deleteprefixdialog = false;
        }
      }
    },
    computed: {
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      date_today_log() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        today = dd + "/" + mm + "/" + yyyy + "/" + time;
        return today;
      },
    }
  };
  </script>
  